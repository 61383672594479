import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import parse from "html-react-parser"
import React, { useEffect } from "react"
import CarouselComponent from "./../../common/carousel-component"
import "./RotationCard.scss"

const RotationCards = ({ data, Cluster }) => {
  const responsive = {
    tablet: {
      breakpoint: { max: 1024, min: 900 },
      items: 2,
    },
    mobile4: {
      breakpoint: { max: 900, min: 800 },
      items: 2,
    },
    mobile4: {
      breakpoint: { max: 800, min: 700 },
      items: 1.8,
    },
    mobile3: {
      breakpoint: { max: 700, min: 600 },
      items: 1.4,
    },
    mobile2: {
      breakpoint: { max: 600, min: 500 },
      items: 1.2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const outerHeading = document.getElementsByClassName("rotate_heading")
    const innerHeading = document.getElementsByClassName("inner_heading")
    const rotateCard = document.getElementById("rotate_card")

    for (var i = 0, len = rotateCard.childElementCount; i < len; ++i) {
      if (i) {
        let opacVal = rotateCard.children[i]?.style?.opacity
        outerHeading[i - 1].style.opacity = 1 - opacVal
        if (opacVal > 0.98) {
          innerHeading[i].style.opacity = 1
        } else {
          innerHeading[i].style.opacity = 0
        }
      }
    }
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

    gsap.registerPlugin(ScrollTrigger)

    const matchMedia = gsap.matchMedia()

    matchMedia.add("(min-width: 992px)", () => {
      gsap.fromTo(
        ".custom-card-desk:not(:first-child)",
        {
          x: 0,
          rotate: -240,
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          stagger: 1,
          rotate: 0,
          scrollTrigger: {
            pin: ".cards-desk",
            scrub: 0.8,
            start: "top bottom-=700px",
            end: "bottom center-=200px",
            invalidateOnRefresh: true,
          },
        }
      )
    })
  }, [])

  return (
    <>
      <div className="rotate-cards mobile mb100">
        <CarouselComponent responsive={responsive} swipeable>
          {data.content.map((el, idx) => {
            return (
              <>
                <div className="rotate-heading text-center prelative">
                  <p className="font-white one m-0">{data.title[idx]}</p>
                  <h1 className="two font-white fw-bold font-roboto position-relative">
                    {data.heading[idx]}
                  </h1>
                </div>
                <div className={`custom-card text-center`}>
                  <div
                    className="bg-col w-100 h-100"
                    style={{ backgroundImage: `url(${data.image[idx].url})` }}
                  >
                    <div className="altv1">
                      <h5
                        className={`inner-heading text mx-auto font-white mb16`}
                      >
                        {parse(el.html)}
                      </h5>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </CarouselComponent>
      </div>
      <div className="rotate-cards desktop">
        <div className="cards cards-desk prelative mx-auto cluster">
          {Cluster}
          <div id="rotate_card">
            {data.content.map((el, idx) => {
              return (
                <>
                  <div
                    className={`custom-card custom-card-desk position-absolute text-center`}
                  >
                    <div
                      className="bg-col w-100 h-100"
                      style={{ backgroundImage: `url(${data.image[idx].url})` }}
                    >
                      <div className="rotate-heading rotate_heading prelative text-center">
                        <p className="font-white one m-0">{data.title[idx]}</p>
                        <h1 className="two font-white fw-bold font-roboto position-relative">
                          {data.heading[idx]}
                        </h1>
                      </div>

                      <div className="altv1">
                        <h5
                          className={`inner-heading inner_heading text mx-auto font-white mb16 ${
                            idx === 0 ? "opac-1" : "opac-0"
                          }`}
                        >
                          {parse(el.html)}
                        </h5>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </div>
          <div className="bg-smoother position-absolute"></div>
        </div>
      </div>
    </>
  )
}

export default RotationCards
