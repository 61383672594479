import React, { useEffect } from "react"
import {
  AccordionHeader,
  AccordionNode,
  AccordionPanel,
  AccordionWithHeader,
} from "react-accordion-with-header"
import "../../../styles/component/faq.scss"
import CarouselComponent from "../carousel-component"
import "./custom-accordian.scss"

const NewAccordianV4 = props => {
  const { data } = props

  const DropdownContents = props => {
    const { title, description } = props
    return (
      <div className="box br10 font-white position-relative d-flex flex-column">
        <p className="text font-white fw-bold mb12 p16">{title}</p>
        <p className="subtext m-0 p16">{description}</p>
      </div>
    )
  }

  useEffect(() => {
    const getac = document.querySelectorAll(".wrap-qn-new")

    if (props.isVisible)
      for (let i = 0; i < getac.length; i++) {
        setTimeout(() => {
          const getclick = document.querySelectorAll(".accordion-new")
          getac[i].classList.add("accordAnimate")
          i == getac.length - 1 &&
            setTimeout(() => {
              getclick[0].click()
            }, i * 10)
        }, i * 100)
      }
  }, [props.isVisible])
  return (
    <AccordionWithHeader
      onChange={(panels, state) => console.log(panels, state)}
      actionCallback={(panels, state) => {
        props.callback(state.active[0])
      }}
      className="myaccord"
      style={{ boxShadow: "none" }}
    >
      {data.map((q, i) => {
        return (
          <AccordionNode key={i} className="wrap-qn-new">
            <AccordionHeader
              className="accordion-new font-roboto"
              style={{
                padding: "30px 24px",
              }}
            >
              <p className="m-0 text">{q.title[0]}</p>
            </AccordionHeader>
            <AccordionPanel className="card-body-new" speed={200}>
              <div>
                <div className="content">
                  <p className="m-0 font-white">{q.content[0].text}</p>
                </div>
                <div className="mob-content">
                  <CarouselComponent
                    items={1}
                    swipeable
                    customButtonNew
                    status
                    partialVisbile
                    customButtonclass="home-carousel"
                  >
                    {q.text.map((item, idx) => {
                      return (
                        <DropdownContents
                          title={item}
                          description={q.subtext[idx]}
                        />
                      )
                    })}
                  </CarouselComponent>
                  <div className="mt32 img-wrap prelative">
                    <img src={q.image[0].url} className="w-100" />
                  </div>
                </div>
              </div>
            </AccordionPanel>
          </AccordionNode>
        )
      })}
    </AccordionWithHeader>
  )
}
export default NewAccordianV4
