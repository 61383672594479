import { StaticQuery, graphql } from "gatsby"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import CustomAccordion from "../components/common/accordian"
import AccordionV4 from "../components/common/accordionV4"
import Frame from "../components/common/frame"
import PopupSignup from "../components/common/popup-sign-up"
import RotationCards from "../components/common/Scrolling-container/RotationCard"
import Navigation from "../components/navigation"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/startups.scss"
const MSPLiftOff = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  let levelCross = 1

  const handleVisibility = () => {
    const rocket = document.getElementById("rocket_launch")
    const twoBalls = document.getElementById("two_balls")
    const mainHero = document.getElementById("main_hero")
    const mainHeroParams = mainHero?.getBoundingClientRect()

    const windowPanel = document.getElementById("window_panel")
    const launchMarker = document
      .getElementById("launch_marker")
      ?.getBoundingClientRect()

    if (rocket && twoBalls) {
      rocket.style.top = `-${mainHeroParams?.height - launchMarker.top}px`
      twoBalls.style.top = `-${(mainHeroParams?.height - launchMarker.top) /
        3}px`
    }

    if (launchMarker?.top < 700 && levelCross) {
      levelCross = 0
      scrollToWindowPanel()
      windowPanel.classList.add("mission-on")
    }
  }

  const scrollToWindowPanel = () => {
    gsap.registerPlugin(ScrollToPlugin)
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: "#window_panel" },
    })
  }

  const Cluster = () => {
    return new Array(10).fill("").map((_, idx) => {
      return <div className={`star position-absolute z-1 count-${idx + 1}`} />
    })
  }

  const [popupVisibility, setPopupVisibility] = useState(false)

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  return (
    <div className="startups">
      <StaticQuery
        query={graphql`
          query mspLiftOff {
            SuperOps {
              pages(where: { title: "MSP-lift-off" }) {
                title
                linkTitle
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }

                newField {
                  ... on SuperOps_Card {
                    title
                    heading
                    cardName
                    cardDescription
                    slug
                    links
                    text
                    subtext
                    content {
                      html
                    }
                    image {
                      url
                    }
                    picture {
                      url
                    }
                  }
                  ... on SuperOps_MultipleCard {
                    cards {
                      cardName
                      title
                      text
                      subtext
                      content {
                        text
                        html
                      }
                      image {
                        url
                      }
                    }
                  }
                  ... on SuperOps_FaqComponent {
                    __typename
                    faqHeading: heading
                    faqs {
                      answerrt {
                        text
                        html
                      }
                      question
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page

              return (
                <div>
                  <Frame
                    seo={seo}
                    ogType="website"
                    FooterName="Super-Summit"
                    footerClassName="super-summit pt-0"
                  >
                    <header>
                      <Navigation
                        page="startups"
                        dskScroll={400}
                        tabs={[
                          {
                            name: "Why MSP LiftOff?",
                            id: "why_msp_lift_off",
                          },
                          {
                            name: "About the program",
                            id: "about_the_program",
                          },
                          {
                            name: "How it works",
                            id: "startup_eligibility",
                          },
                          {
                            name: "Pricing",
                            id: "pricing",
                            to: "/pricing",
                          },
                          {
                            name: "FAQ",
                            id: "startup_faq",
                          },
                        ]}
                        btnText={newField[5].slug}
                        togglePopup={() => togglePopup()}
                      />
                    </header>

                    <div>
                      <PopupSignup
                        visibility={popupVisibility}
                        togglePopup={togglePopup}
                        disallowCommonDomains
                        programName="Startup Program"
                      />
                    </div>

                    <section id="main_hero" className="hero prelative cluster">
                      <div className="left-pink position-absolute d-dsk">
                        <img src={newField[5].image[0].url} className="w-100" />
                      </div>
                      <div className="right-pink position-absolute d-dsk">
                        <img src={newField[5].image[2].url} className="w-100" />
                      </div>
                      <Container className="Layout-container prelative">
                        <Cluster />

                        <h1 className="font-roboto fw-bold heading text-center down-to-up-1">
                          {newField[5].cardName}
                        </h1>

                        <p className="sub-heading one font-white fw-500 text-center m-0 down-to-up-2">
                          {newField[5].title[0]}
                        </p>
                        <p className="sub-heading two fw-bold text-center down-to-up-2">
                          {newField[5].title[1]}
                        </p>

                        <div
                          id="two_balls"
                          className="d-flex justify-content-between mt60 prelative down-to-up-3"
                        >
                          <div className="circle first">
                            <div className="inner-layer d-flex flex-column align-items-center justify-content-center h-100 w-100">
                              {newField[5].text.map((item, idx) => {
                                return (
                                  <p
                                    className={`m-0 text font-white fw-bold ${
                                      idx === 0 ? "one" : "two"
                                    }`}
                                  >
                                    {item}
                                  </p>
                                )
                              })}
                            </div>
                          </div>
                          <div className="circle second">
                            <div className="inner-layer h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                              {newField[5].subtext.map(item => {
                                return (
                                  <p className="m-0 font-white text two fw-bold">
                                    {item}
                                  </p>
                                )
                              })}
                            </div>
                          </div>
                        </div>

                        <div
                          id="rocket_launch"
                          className="rocket-launch text-center position-relative mx-auto cursor-pointer down-to-up-4"
                          onClick={() => scrollToWindowPanel()}
                        >
                          <div className="bg-ellipse position-absolute d-dsk">
                            <img
                              src={newField[5].image[1].url}
                              className="w-100"
                            />
                          </div>
                          <iframe
                            src="https://rive.app/s/PmF1wfxrwU6nXttAOxUy0g/embed"
                            className="rocket"
                          ></iframe>
                        </div>

                        <div id="launch_marker" className="w-100 h-1px"></div>
                      </Container>
                    </section>

                    <section id="window_panel" className="window">
                      <Container className="Layout-big">
                        <h1 className="font-white heading font-roboto fw-bold mx-auto text-center mb24">
                          {newField[6].title[0]}{" "}
                          <span className="text">{newField[6].title[1]}</span>
                        </h1>
                        <p className="font-white mx-auto description text-center mb40">
                          {newField[6].text[0]}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="gradient-btn font-white fw-600 mb40"
                            onClick={() => togglePopup()}
                          >
                            {newField[1].subtext[0]}
                          </button>
                        </div>
                      </Container>
                    </section>

                    <div id="why_msp_lift_off" />
                    <section className="spark-heading">
                      <Container className="Layout-container">
                        <div className=" text-center">
                          <Fade bottom duration={1000}>
                            <h1 className="one font-white fw-600">
                              {newField[6].heading[0]}
                            </h1>
                            <p className="two font-roboto fw-bold">
                              {newField[6].heading[1]}
                            </p>
                          </Fade>
                        </div>
                      </Container>
                    </section>

                    <RotationCards data={newField[7]} Cluster={<Cluster />} />

                    <section className="author-note">
                      <Container className="Layout-container prelative">
                        <div className="left-ellipse position-absolute d-dsk">
                          <img
                            src={newField[3].image[2].url}
                            className="w-100"
                          />
                        </div>
                        <div className="right-ellipse position-absolute d-dsk">
                          <img
                            src={newField[3].image[3].url}
                            className="w-100"
                          />
                        </div>
                        <Fade bottom duration={1000}>
                          <h1 className="font-white font-roboto heading text-center mx-auto mb60">
                            {newField[3].cardName}
                          </h1>
                        </Fade>
                        <div className="box-cover d-flex flex-wrap row justify-content-center align-items-end">
                          <Col lg={4} className="d-flex justify-content-center">
                            <Fade duration={1000}>
                              <div className="img-wrap">
                                <img
                                  src={newField[3].image[0].url}
                                  className="w-100"
                                />
                              </div>
                            </Fade>
                          </Col>
                          <Col lg={6} className="right">
                            <Fade bottom duration={1000}>
                              <h1 className="font-white font-roboto altv3 mb24 title">
                                {newField[3].text[0]}
                              </h1>
                            </Fade>
                            <Fade bottom duration={1000}>
                              <p className="font-white p16 mb50">
                                {newField[3].subtext[0]}
                              </p>
                            </Fade>
                            <Fade bottom duration={1000}>
                              <img
                                src={newField[3].image[1].url}
                                className="sign"
                              />
                              <p className="font-white m-0">
                                {newField[3].subtext[1]}
                              </p>
                            </Fade>
                          </Col>
                        </div>
                      </Container>
                    </section>

                    <section className="accordions" id="about_the_program">
                      <Container className="Layout-container">
                        <Fade bottom duration={1000}>
                          <h1 className="font-white font-roboto fw-bold text-center mx-auto heading">
                            {newField[8].cards[0].cardName}
                          </h1>
                        </Fade>
                      </Container>
                      <AccordionV4 data={newField[8].cards} />
                    </section>

                    <section className="why-choose">
                      <Container className="Layout-container d-flex flex-wrap gap-24 prelative">
                        <div className="left-blue position-absolute d-dsk">
                          <img
                            src={newField[2].image[0].url}
                            className="w-100"
                          />
                        </div>
                        <div className="left">
                          <Fade bottom duration={1000}>
                            <h1 className="heading font-roboto font-white fw-bold">
                              {newField[2].cardName}
                            </h1>
                          </Fade>
                          <Fade bottom duration={1000}>
                            <button
                              className="gradient-btn font-white fw-600 d-dsk"
                              onClick={() => togglePopup()}
                            >
                              {newField[2].title[0]}
                            </button>
                          </Fade>
                        </div>
                        <Fade bottom duration={1000}>
                          <div className="right d-flex flex-wrap justify-content-between gap-24">
                            {newField[2].text.map((item, idx) => {
                              return (
                                <div className="gradient-border card">
                                  <p className="font-white font-roboto fw-bold title p22 mb12">
                                    {item}
                                  </p>
                                  <p className="font-white">
                                    {newField[2].subtext[idx]}
                                  </p>
                                </div>
                              )
                            })}
                          </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                          <button
                            className="gradient-btn font-white fw-600 d-mob w-100"
                            onClick={() => togglePopup()}
                          >
                            {newField[2].title[0]}
                          </button>
                        </Fade>
                      </Container>
                    </section>

                    <div id="startup_eligibility" />
                    <section className="eligibility">
                      <Container className="Layout-container prelative d-flex flex-wrap gap-24 mx-auto">
                        <div className="right-pink position-absolute d-dsk">
                          <img
                            src={newField[1].image[0].url}
                            className="w-100"
                          />
                        </div>
                        <div className="left">
                          <Fade bottom duration={1000}>
                            <h1 className="heading font-roboto altv3 font-white fw-bold">
                              {newField[1].cardName}
                            </h1>
                          </Fade>
                          <Fade bottom duration={1000}>
                            <button
                              className="gradient-btn font-white fw-600 d-dsk"
                              onClick={() => togglePopup()}
                            >
                              {newField[1].subtext[0]}
                            </button>
                          </Fade>
                        </div>
                        <Fade bottom duration={1000}>
                          <div className="right d-flex flex-column gap-32">
                            {newField[1].text.map((item, idx) => {
                              return (
                                <div className="d-flex flex-row gap-16 gradient-border card">
                                  <span className="font-white fw-bold num">
                                    {"0" + (idx + 1)}
                                  </span>
                                  <p className="m-0 font-white font-roboto p22 fw-600">
                                    {item}
                                  </p>
                                </div>
                              )
                            })}
                          </div>
                        </Fade>
                        <Fade bottom duration={1000}>
                          <button
                            className="gradient-btn font-white fw-600 d-mob w-100"
                            onClick={() => togglePopup()}
                          >
                            {newField[1].subtext[0]}
                          </button>
                        </Fade>
                      </Container>
                      <div id="startup_faq" />
                    </section>

                    <section className="faq m-0">
                      <Container className="Layout-container">
                        <Col lg={10} className="mx-auto">
                          <Fade bottom duration={1000}>
                            <h1 className="altv3 font-roboto font-white fw-bold text-center">
                              {newField[0].faqHeading}
                            </h1>
                          </Fade>
                          <div className="outer">
                            <CustomAccordion items={newField[0].faqs} />
                          </div>
                        </Col>
                      </Container>
                    </section>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default MSPLiftOff
