// import CustomAccordion from "./accordian"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import TrackVisibility from "react-on-screen"
import Fade from "react-reveal/Fade"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pages/affiliate.scss"
import "../../styles/pricing-new.scss"
import NewAccordianV4 from "./Accordian-new/custom-accordion-v4"

const AccordionV4 = props => {
  const { data } = props

  const [activeAccordion, setActiveAccordion] = useState(0)

  const callbackFun = data => {
    const accprevIndex = parseInt(sessionStorage.getItem("accprevindex"))
    data != null ? setActiveAccordion(data) : setActiveAccordion(accprevIndex)

    sessionStorage.setItem("accprevindex", data != null ? data : accprevIndex)
  }
  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem("accprevindex")
    }
    const checkPrev = () => {
      var accprevIndex = sessionStorage.getItem("accprevindex")
      if (accprevIndex === null) {
        sessionStorage.setItem("accprevindex", 0)
      }
    }
    checkPrev()
  }, [])

  const FadeChildren = props => {
    return (
      <Fade delay={100} bottom distance="30px">
        {props.children}
      </Fade>
    )
  }
  const DropdownContents = props => {
    const { title, description } = props
    return (
      <FadeChildren>
        <div className="box br10 position-relative d-flex flex-column">
          <p className="text font-white fw-bold p22 mb12">{title}</p>
          <p className="subtext m-0 font-white p18">{description}</p>
        </div>
      </FadeChildren>
    )
  }

  return (
    <>
      <section className="accordionV4 d-flex justify-content-center">
        <div
          className="layout-width outer d-flex justify-content-center"
          id="bgchange"
        >
          <div className="Layout-container">
            <Row className="justify-content-between">
              <Col lg={4}>
                <div className="left mb24">
                  <TrackVisibility
                    once
                    children
                    partialVisibility={true}
                    offset={-200}
                  >
                    <NewAccordianV4 data={data} callback={callbackFun} />
                  </TrackVisibility>
                </div>
              </Col>
              <Col lg={8}>
                <div className="d-dsk right">
                  <div className="position-relative d-flex align-items-center justify-content-between h100 flex-column">
                    <div className="d-flex flex-wrap justify-content-center row-gap-24 col-gap-24">
                      {data[activeAccordion].text.map((item, idx) => {
                        return (
                          <DropdownContents
                            title={item}
                            description={data[activeAccordion].subtext[idx]}
                          />
                        )
                      })}
                    </div>
                    <div
                      className="position-relative"
                      style={{ minHeight: "370px" }}
                    >
                      {data.map((item, i) => (
                        <div
                          className={`image-wrapper ${
                            activeAccordion === i
                              ? "scaleFadeAnime"
                              : "fade-out-effect"
                          } position-absolute d-dsk`}
                        >
                          <img
                            src={item.image[0].url}
                            alt="Hero"
                            width="742px"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  )
}

export default AccordionV4
